import PropTypes from "prop-types";
import { memo } from "react";
// @mui
import { Button, Stack } from "@mui/material";
//
import { NavListRoot } from "./NavList";
import useAuth from "src/hooks/useAuth";
import Ternary from "src/components/ternary";
import { PATH_USER } from "src/routes/paths";
import { Link } from "react-router-dom";

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

NavSectionHorizontal.propTypes = {
  navConfig: PropTypes.array,
};

function NavSectionHorizontal({ navConfig }) {
  const {
    isAdmin,
    isSubAdmin,
    user: { user_type },
  } = useAuth();
  const isClient = user_type === "Client";
  return (
    <Stack
      direction="row"
      justifyContent="center"
      sx={{
        background: "linear-gradient(235deg, #2a2146, #674ab6)",
        borderRadius: 1,
        px: 0.5,
      }}
    >
      <Stack direction="row" sx={{ ...hideScrollbar, py: 1 }}>
        {navConfig.map((group) => (
          <Stack key={group.subheader} direction="row" flexShrink={0}>
            {group.items.map((list) => (
              <NavListRoot key={list.title} list={list} />
            ))}
          </Stack>
        ))}
        <Ternary
          when={isAdmin || isSubAdmin || isClient}
          otherwise={
            <Button
              sx={{
                backgroundColor: "#fff",
                color: "#000",
                fontWeight: 400,
                "&:hover": {
                  backgroundColor: "#e4dfdf",
                },
              }}
              to={PATH_USER.service_provider}
              component={Link}
              variant="contained"
            >
              DEPOSIT NOW
            </Button>
          }
        />
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
