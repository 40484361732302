import { Box, Button, List } from "@mui/material";
import PropTypes from "prop-types";
import { NavListRoot } from "./NavList";
import { Link } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import Ternary from "src/components/ternary";
import { PATH_USER } from "src/routes/paths";

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({
  key,
  navConfig,
  isCollapse = false,
  ...other
}) {
  const {
    isAdmin,
    isSubAdmin,
    user: { user_type },
  } = useAuth();
  const isClient = user_type === "Client";
  return (
    <Box {...other}>
      {navConfig.map((group, i) => {
        return (
          <List key={i} disablePadding sx={{ px: 2 }}>
            {group.items.map((list) => {
              return (
                <NavListRoot
                  key={list.title}
                  list={list}
                  isCollapse={isCollapse}
                />
              );
            })}
            <Ternary
              when={isAdmin || isSubAdmin || isClient}
              otherwise={
                <Button
                  sx={{
                    backgroundColor: "#fff",
                    color: "#000",
                    fontWeight: 400,
                    mt: 2,
                    ml: "50px",
                    "&:hover": {
                      backgroundColor: "#e4dfdf",
                    },
                  }}
                  to={PATH_USER.service_provider}
                  component={Link}
                  variant="contained"
                >
                  DEPOSIT NOW
                </Button>
              }
            />
          </List>
        );
      })}
    </Box>
  );
}
