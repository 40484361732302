import React from "react";
import { Navigate } from "react-router";
import Ternary from "src/components/ternary";
import useAuth from "src/hooks/useAuth";

const UserTypeConfirm = ({ children }) => {
  const {
    user: { user_type },
  } = useAuth();
  const isAffiliate = user_type === "Affiliate";
  return (
    <Ternary
      when={isAffiliate}
      then={children}
      otherwise={<Navigate to="404" replace />}
    />
  );
};

export default UserTypeConfirm;
